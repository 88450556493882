import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Menubar, Title, StyledImg, PageFooter } from './components';
import { openPage } from "./components";
import { CardsWidget, AboutWidget, InfoWidget, TeamMemberWidget, PageTitleWidget, SubTitleWidget, ContactWidget } from './widgets';
import reportWebVitals from './reportWebVitals';

//Cards widget
/*These are the cards for the element that Cycles though info. Orientation is where the 
text element goes t and b respectfully are top and bottom with the words either Right 
or Left other positioning. Always follow the format tRight, otherwise it will break*/
const cards = [
  {
    'name': 'dogWalking',
    'img': '/Images/petCollage.jpg',
    'text': 'Working with our many families to provide the best level of care',
    'orientation': 'tRight',
    "buttonTitle": "Learn More",
    "buttonPage": "Services"
  },
  {
    'name': 'houseSitting',
    'img': '/Images/scheduleToday.jpg',
    'text': 'Want quality service for your pets and home? Get a quote today!',
    'orientation': 'tRight',
    "buttonTitle": "Get a Quote",
    "buttonPage": "Schedule"
  }
]
const root = ReactDOM.createRoot(document.getElementById('root'));

//Pages elements
/*Page elements. Orientation is either left or right and it pertains to the position of the
button that links to page in the tab menu. There can only be one right (direction) element. The 
SVG are the respective icons that are for those buttons. They need to be monochrome and 
preferably one path with that path's fill set to fill-textColor to keep colors consistent*/
const pages = [{
  'text': 'Home',
  'icon': <svg
    className=" w-[50px] h-[50px] ml-[7.5px] mt-[7.5px] sm:ml-0 sm:mt-0 sm:w-[40px]"
    viewBox="0 0 1080 1080"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className=' fill-textColor'
      d="M420.89 548.74h238.22v416.75H420.89V548.74zm-250.85 0h95.376v95.376H170.04V548.74zm113.52 0h95.375v95.376H283.56V548.74zM170.04 661.41h95.376v95.376H170.04V661.41zm781.52-240.52v526.52c0 25.373-20.599 45.973-45.972 45.973h-731.19c-25.373 0-45.972-20.6-45.972-45.973V420.89H53.041c-15.477 0-29.807-2.93-37.602-7.676-7.796-4.753-7.842-10.614-.184-15.392 101.69-63.352 384.41-239.52 486.99-303.41h.023c7.727-4.818 22.149-7.798 37.763-7.798 15.591 0 30.036 2.98 37.763 7.798 102.53 63.897 385.28 240.06 486.95 303.41 7.658 4.778 7.566 10.639-.184 15.392-7.795 4.754-22.102 7.676-37.602 7.676h-75.402zM282.43 661.41h95.376v95.376H282.43V661.41z"
      fillRule="evenodd"
    />
  </svg>,
  'orientation': 'middle',
  'children': []
},
{
  'text': 'Services',
  'icon': <svg
    className=" w-[50px] h-[50px] ml-[7.5px] mt-[7.5px] sm:ml-0 sm:mt-0 sm:w-[40px]"
    viewBox="0 0 1080 1080"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className=' fill-textColor'
      d="M243.4 439.82c-39.904-85.575-121.16-132.27-181.35-104.2s-76.65 120.33-36.746 205.9c39.905 85.575 121.16 132.27 181.35 104.2s76.65-120.33 36.745-205.9zm254.39-225.04c-20.574-103.1-95.261-175.24-166.68-160.99-71.42 14.251-112.7 109.53-92.125 212.63s95.262 175.24 166.68 160.99c71.42-14.252 112.7-109.53 92.125-212.63zM836.6 439.82c39.904-85.575 121.16-132.27 181.35-104.2s76.65 120.33 36.746 205.9c-39.905 85.575-121.16 132.27-181.35 104.2s-76.65-120.33-36.745-205.9zM582.21 214.78c20.574-103.1 95.261-175.24 166.68-160.99 71.42 14.251 112.7 109.53 92.125 212.63s-95.262 175.24-166.68 160.99c-71.42-14.252-112.7-109.53-92.125-212.63zm-39.129 811.75q155.2-.532 191.2 1.401c56.464 3.032 385.09-98.493 125.96-306.44-40.108-37.85-113.65-71.91-179.92-195.52-59.485-110.96-104.96-123.07-138.18-122-33.222-1.071-78.696 11.046-138.18 122-66.271 123.61-139.82 157.67-179.92 195.52-259.12 207.95 69.497 309.47 125.96 306.44q36.004-1.933 191.2-1.401v.007l.944-.004.936-.003z"
      fillRule="evenodd"
    />
  </svg>,
  'orientation': 'middle',
  'children': []
},
{
  'text': 'About Us',
  'icon': <svg
    className=" w-[50px] h-[50px] ml-[7.5px] mt-[7.5px] sm:ml-0 sm:mt-0 sm:w-[40px]"
    viewBox="0 0 1080 1080"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M431.89 488.81c-149.06 15.929-262.64 96.981-262.64 194.44v264.765c0 70.46 57.205 127.66 127.66 127.66h377.45c70.46 0 127.66-57.204 127.66-127.66V670c0-92.437-109.55-169.06-252.06-182.08 93.278-26.413 161.69-112.25 161.69-213.94 0-122.71-99.624-222.33-222.33-222.33-122.71 0-222.33 99.624-222.33 222.33 0 102.85 69.986 189.48 164.89 214.83zM842.53 46.39h.5c37.365 0 67.7 30.335 67.7 67.7v167.27c0 37.364-30.335 67.699-67.7 67.699h-.5c-37.365 0-67.7-30.335-67.7-67.699V114.09c0-37.365 30.335-67.7 67.7-67.7zm-67.7 395.66c0-37.503 30.448-67.95 67.95-67.95 37.503 0 67.95 30.447 67.95 67.95S880.283 510 842.78 510c-37.502 0-67.95-30.447-67.95-67.95z"
    />
  </svg>,
  'orientation': 'middle',
  'children': []
},
{
  'text': 'Schedule',
  'icon': <svg
    className=" w-[50px] h-[50px] ml-[7.5px] mt-[7.5px] sm:ml-0 sm:mt-0 sm:w-[40px]"
    viewBox="0 0 1080 1080"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h1080v1080H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path className=' fill-textColor' d="M296.91 416.16c82.56 162.25 217.31 299.28 379.56 382.41l59.039-76.253c15.48-15.48 320.49 44.72 320.49 76.253v200.09c0 31.533-25.8 57.333-57.333 57.333-538.36 0-974.67-436.31-974.67-974.67 0-31.533 25.8-57.333 57.333-57.333h200.67c31.533 0 91.733 304.44 75.68 320.49l-60.773 71.667z" />
    </g>
  </svg>,
  'orientation': 'right',
  'children': []
}];

//Main Page Elements
/*These are the child  elements that will be rendered on the main page or the first page the user sees*/
const mainPageChildren = [
  <CardsWidget cards={cards} />,
  <AboutWidget info="Forming strong bonds not just with pets but with their owners is very important to us. We work closely with you to understand your pet's unique needs and your specific home care requirements. Our team is reliable, professional, and thoroughly vetted. You can count on us to be there. Join our family today, and let us show you why we are the best choice in pet and home care services." />,
  (<InfoWidget title='About Us' src='/Images/greatService.jpg' orientation='right' pos="lg:mt-[calc(70vh_+_30px)]">
    <h2>We are a team of passionate pet lovers committed to providing the highest quality PET CARE and HOUSE-SITTING services.</h2><button className="w-fit h-fit p-[10px] bg-secondary rounded-[25px] mt-[10px] text-[20px] font-bold" onClick={() => {openPage("About Us")}}>Learn More</button></InfoWidget>),
  <PageFooter pages={pages} />
];

/*These are the child elements that will be rendered on the services page*/
const servicesPageChildren = [
  <PageTitleWidget text='Services' orientation="left" />,
  (<InfoWidget title='Daily Walks' src='/Images/qualityWalks.jpg' orientation='right'><h2>We believe in quality over quantity, which is why we offer individual dog-walking services. Each walk is tailored to your pet's specific needs, ensuring they get the necessary exercise and mental stimulation they need to stay healthy and happy. We treat your furry friends with the utmost respect and care, ensuring they return home safely after their fun-filled walk.</h2></InfoWidget>),
  (<InfoWidget title='Other Pet Care' src='/Images/otherPet.jpg' orientation='left'><h2>We care for pets of all types and love them all! In fact, the more the merrier. We treat every pet as if they were our own, ensuring they receive plenty of love, attention, and comfort in your absence. We are eager to meet you in person to learn every detail of what you will need for us to provide.
  </h2></InfoWidget>),
  (<InfoWidget title='While You Are Out' src='/Images/homeSitting.jpeg' orientation='right'><h2>Going on a business trip or a vacation? Leaving your home unattended can be stressful. No worries, we've got you covered! With our Out of Town services, we ensure your home is running smoothly. Our team will handle everything from collecting your mail/packages , watering your plants, managing trash days, etc. You no longer have to worry about your plants withering or your mail piling up while you're away.</h2></InfoWidget>),
  <PageFooter pages={pages} />
];
const aboutUsChildern = [
  <PageTitleWidget text='About Us' orientation="left" />,
  (<InfoWidget title='About Us' src='/Images/aboutUs.jpg' orientation='right'><h2>We are a team of passionate pet lovers committed to providing the highest quality PET CARE and HOUSE-SITTING services. Established in 2013, our mission has always been to provide peace of mind while you are away. We ensure pets are happy, healthy, and loved, in the comforts of their own home environment. Travel confidently, knowing that your pet and home are in the best of care. Every service we provide is executed with care - because we understand how much your pets mean to you.</h2></InfoWidget>),
  <TeamMemberWidget name="Dora" direction="left" src='/Images/Dora.jpeg' title="Owner/Primary Sitter" className=" rounded-[30px] drop-shadow-lg h-fit w-[calc(100%_-_20px)] p-[10px] ml-[10px] mt-[15px] mb-[15px] bg-secondary">After being raised on a farm in the Midwest and around animals/pets my whole life, I am thrilled to have this opportunity to meet you and care for your pets and your home. It is my hope we get to meet soon to discuss how we can best be of service.</TeamMemberWidget>,
  <TeamMemberWidget name="Kevin" src='/Images/Kevin.jpeg' title="Sitter" className=" rounded-[30px] drop-shadow-lg h-fit w-[calc(100%_-_20px)] p-[10px] ml-[10px] mt-[15px] mb-[15px] bg-secondary">I am committed to the best care I can provide to your pets. That incudes being timely and making sure your pet has a good time.</TeamMemberWidget>,
  <PageFooter pages={pages} />
];
//These are the child elements that will be rendered on the schedule page
const schedulePageChildren = [
  <ContactWidget title="Contact Us"><h3 className=' p-[10px]'>Are you in the orange county Area? Contact Us today to get a quote today.</h3><SubTitleWidget text='949-903-3321' /></ContactWidget>,
  <PageFooter pages={pages} />
]

//This sets the page children to be the previously defined children
pages[0].children = mainPageChildren;
pages[1].children = servicesPageChildren;
pages[2].children = aboutUsChildern;
pages[3].children = schedulePageChildren;
console.log(pages);
//This renders the page (unessary to edit for now)
root.render(
  <div className=' overflow-hidden'>

    <Title />
    <Menubar ObjectPages={pages} />
  </div>
);
console.log(pages);
reportWebVitals();

