import React from "react";
import { addStyling, combineStyling } from "./uniStyling";
import { openPage } from "./components";
const navThing = "75px";
let CurrentCard = {};
let cardList = [];
/*
class Switcher extends React.Component {
  constructor(props) {
    super(props);
    this.cardList = [...props.children];
    this.dots = [];
    for(let card of cardList){
      let cardDot = (
        <div
          className="w-[10px] h-[10px] grid content-center justify-items-center items-center rounded-full bg-translucent backdrop-blur"
          id={card.name + "Dot"}
          style={dotStyle}
          onClick={(e) => stopStart(cardList.indexOf(card))}
          onAnimationStart={(e) => {
            animationHandler(cardList.indexOf(card));
          }}
        >
          <div className="w-[100%] h-[100%] bg-accent aspect-square opacity-0 rounded-full backdrop-blur"></div>
        </div>
      );
    }
    this.state = {cardIndex: 0};
  }
  setCardIndex(index){
    this.setState({cardIndex: index});
  }
  render() {
    return (
      <div id="mainDiv" className="w-[calc(100%_-_20px)] left-[10px] top-[10px] h-[calc(70vh_-_20px)] rounded-[30px] absolute bg-secondary bg-center drop-shadow-lg lg:w-[calc(100%_-_365px)]">
        <div id="dotMap" className="grid auto-rows-[35px] w-[35px] self-center top-[50%] translate-y-[-50%] absolute justify-evenly justify-items-center content-evenly items-center">{this.dots}</div>
        {this.cardList[this.state.cardIndex]}
      </div>
    );
  }
}*/
function CardsWidget(props) {
  let styling = [
    {
      name: "outer",
      type: "class",
      styling: `
        width: 10px;
        height: 10px;
        display: grid;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        border-radius: 50%;
        background-color: var(--translucent);
        overflow: hidden;`,
    },
  ];
  cardList = props.cards;
  let dots = [];
  let textualCards = [];
  let workerArry = [];
  for (let card of cardList) {
    let dotStyle = {};
    if (cardList.indexOf(card) == 0) {
      dotStyle = {
        animation: "15.0s ease-in 0s 1 normal forwards running outterDotAni",
      };
    }
    let cardDot = (
      <div
        className="w-[10px] h-[10px] grid content-center justify-items-center items-center rounded-full bg-translucent backdrop-blur"
        id={card.name + "Dot"}
        style={dotStyle}
        onClick={(e) => stopStart(cardList.indexOf(card))}
        onAnimationStart={(e) => {
          animationHandler(cardList.indexOf(card));
        }}
      >
        <div className="w-[100%] h-[100%] bg-accent aspect-square opacity-0 rounded-full backdrop-blur"></div>
      </div>
    );
    dots.push(cardDot);
    card.dot = cardDot;
    let cardStyle = "text-[20px] p-[20px] max-w-[250px] absolute bg-accent rounded-[25px] invisible font-normal drop-shadow-lg backdrop-blur";
    switch (card.orientation) {
      case "bLeft":
        cardStyle += ' bottom-[10px] left-[10px]';
        break;
      case "bRight":
        cardStyle += ' bottom-[10px] right-[10px]';
        break;
      case "tLeft":
        cardStyle += ' top-[10px] left-[10px]';
        break;
      case "tRight":
        cardStyle += ' top-[10px] right-[10px]';
        break;
    }
    let cardText = (
      <h2 id={card.name + "Header"} className={cardStyle}>
        {card.text}
        <button className="w-[100%] h-[50px] bg-secondary rounded-[25px] mt-[10px] text-[20px] font-bold" onClick={() => { openPage(card.buttonPage) }}>{card.buttonTitle}</button>
      </h2>
    );
    textualCards.push(cardText);
    workerArry.push({ name: card.name, link: card.img });
  }
  addStyling(styling);
  return (
    <div id="mainDiv" className="w-[calc(100%_-_20px)] left-[10px] top-[10px] h-[calc(70vh_-_20px)] rounded-[30px] absolute bg-secondary bg-center drop-shadow-lg lg:w-[calc(100%_-_365px)]">
      <div id="dotMap" className="grid auto-rows-[35px] w-[35px] self-center top-[50%] translate-y-[-50%] absolute justify-evenly justify-items-center content-evenly items-center">{dots}</div>
      {textualCards}
    </div>
  );
}
function AboutWidget(props) {
  return (
    <div className="subpixel-antialiased drop-shadow-lg rounded-[30px] border-2px border-secondary p-[10px] top-[70vh] right-[10px] h-fit w-[calc(100%_-_20px)] ml-[10px] mt-[70vh] lg:absolute bg-gradient-to-br from-secondary to-secondaryL lg:h-[calc(70vh_-_20px)] lg:w-[340px] lg:top-[10px] lg:right-[10px] lg:ml-0 lg:mt-0">
      <h2 className="subpixel-antialiased font-bold text-[40px] sm:text-[50px] leading-none">Why Us</h2>
      <h4 className=" text-[23px]">{props.info}</h4>
    </div>
  );
}
function PageTitleWidget(props) {
  let defaultStyle = "w-fit h-[60px] sm:h-[100px] font-bold leading-[40px] sm:leading-[80px] text-[60px] sm:text-[100px] bg-gradient-to-r from-secondary to-secondaryL rounded-[25px] ml-[10px] mt-[10px] p-[10px]";
  if (props.orientation == "left") {
    defaultStyle += ' left-[10px] absolute';
  } else {
    defaultStyle += ' right-[10px] absolute';
  }
  return (
    (<div className="mb-[80px] sm:mb-[120px]"><h1 className={defaultStyle}>{props.text}</h1></div>)
  );
}
function SubTitleWidget(props) {
  return (
    (<div className="w-fit h-fit text-[40px] place-content-center font-bold bg-gradient-to-r from-secondary to-secondaryL rounded-[25px] ml-[10px] mt-[10px] p-[5px] mb-[80px]">{props.text}</div>)
  );
}
function InfoWidget(props) {
  let divStyle = 'rounded-[30px] drop-shadow-lg h-fit w-[calc(100%_-_20px)] p-[10px] ml-[10px] mt-[15px] mb-[15px] grid overflow-clip bg-accent sm:bg-transparent';
  let imgStyle = 'aspect-video w-[100%] rounded-[25px] object-cover drop-shadow-lg';
  let contDiv = "";
  let ori = true;
  if (props.orientation == 'left') {
    divStyle += ' imgLeft';
    contDiv += 'ml-[10px]'
  } else {
    divStyle += ' imgRight';
    ori = false;
  }
  if (props.pos) {
    divStyle += ' ' + props.pos;
  }
  console.log(props.children)
  return (
    <div className={divStyle}>
      {ori ? <img className={imgStyle} src={props.src}></img> : false}
      <div className={contDiv}>
        <h1 className='subpixel-antialiased text-[40px] sm:text-[50px] h-fit font-bold '>{props.title}</h1>
        <div className='text-[23px] pr-[5px]'>{props.children}</div>
      </div>
      {ori ? false : <img className={imgStyle} src={props.src}></img>}
    </div>
  )
}
function TeamMemberWidget(props) {
  return (<div className={props.className}>
    <h1 className="subpixel-antialiased text-[30px] sm:text-[30px] h-[35px] font-bold" >{props.name}</h1>
    <h2 className="subpixel-antialiased text-[20px] h-fit font-normal mb-[5px]"> {props.title}</h2>
    <div className={` flex flex-col sm:${props.direction == "left" ? "flex-row" : "flex-row-reverse"}`}>
      <img className="rounded-full drop-shadow-lg aspect-square w-[150px] h-[150px] sm:w-[200px] sm:h-[200px]" src={props.src}></img>
      <div className="text-[23px] pr-[5px] w-full h-full pl-[10px]">"{props.children}"</div>
    </div>
  </div>)
}
//similar to info widget but without the image and the content is centered
function ContactWidget(props) {
  return (
    <div className=' w-full text-[30px]'>
      <div className='rounded-[30px] drop-shadow-lg h-fit w-[calc(100%_-_20px)] ml-[10px] mt-[15px] mb-[15px] grid content-center justify-items-center items-center  goverflow-hidden'>
        <h1 className='subpixel-antialiased text-[40px] sm:text-[50px] h-fit font-bold p-[10px]'>{props.title}</h1>
        {props.children}
      </div>
    </div>
  )
}
function animationHandler(idx) {
  let mainDiv = document.getElementById("mainDiv");
  let arry = cardList;
  let card = arry[idx];
  let outer = document.getElementById(card.name + "Dot");
  let header = document.getElementById(card.name + "Header");
  let inner = outer.childNodes[0];
  CurrentCard = {
    inner: inner,
    outer: outer,
    header: header,
  }
  outer.style.animation =
    "15.0s ease-in 0s 1 normal forwards running outterDotAni";
  inner.style.animation =
    "15.0s ease-in 0s 1 normal forwards running innerDotAni";
  header.style.visibility = "inherit";
  header.style.animation = "14.8s linear 0s 1 normal forwards running cardFade";
  mainDiv.style.backgroundImage = `url('${card.img}')`;
  setTimeout(() => {
    if (inner.style.animation != "") {
      header.style.visibility = "hidden";
      header.style.animation = "";
    }
  }, 14800);
  inner.addEventListener('animationend', () => {
    inner.style.animation = undefined;
    outer.style.animation = undefined;
    header.style.visibility = "hidden";
    if (idx == arry.length - 1) {
      animationHandler(0);
    } else {
      animationHandler(idx + 1);
    }
  }, { once: true });
}
function stopStart(index) {
  CurrentCard.inner.style.animation = "";
  CurrentCard.outer.style.animation = "";
  CurrentCard.header.style.animation = "";
  CurrentCard.header.style.visibility = "hidden";
  animationHandler(index);
}
function InfoCard(props) { }
export { CardsWidget, AboutWidget, InfoWidget, TeamMemberWidget, PageTitleWidget, SubTitleWidget, ContactWidget };
