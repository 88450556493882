function addStyling(){
    let elemArray = undefined;
    let targetElem = undefined;
    if(arguments.length == 1){
        elemArray = arguments[0]
        targetElem = document.getElementById('componentStyling')
    }else{
        elemArray = arguments[0]
       targetElem = arguments[1]
    }
    let styling = targetElem.innerHTML;
    for(let elem of elemArray){
        if(elem.type == 'class' || elem.type == 'id'){
            let typeNote = elem.type == 'class' ? '.': '#';
            let fullDef = typeNote + elem.name;
            
            if(styling.indexOf(fullDef+'{') == -1){
                targetElem.innerHTML += typeNote+elem.name+'{'+ elem.styling+'}';
                styling = targetElem.innerHTML;
            }
            else{
                let sub = styling.substring(styling.indexOf(fullDef+'{')+fullDef.length+1)
                let preStyling = sub.substring(0, sub.indexOf('}'))
                if (preStyling != elem.styling){
                    let index = styling.indexOf(sub)
                    let endIndex = sub.indexOf('}')
                    styling= styling.slice(index, index + endIndex);
                    styling = styling.substring(0,index) + typeNote+elem.name+"{"+elem.styling+"}" + styling.substring(index)
                }
            }
        }else if (elem.type == 'keyframes'){
            let typeNote = '@keyframes ';
            let fullDef = typeNote + elem.name;
            
            if(styling.indexOf(fullDef+'{') == -1){
                targetElem.innerHTML += typeNote+elem.name+'{'+ elem.styling+'}';
                styling = targetElem.innerHTML;
            }
            else{
                let sub = styling.substring(styling.indexOf(fullDef+'{')+fullDef.length+1)
                let preStyling = sub.substring(0, sub.indexOf('}'))
                if (preStyling != elem.styling){
                    let index = styling.indexOf(sub)
                    let endIndex = sub.indexOf('}')
                    styling= styling.slice(index, index + endIndex);
                    styling = styling.substring(0,index) + typeNote+elem.name+"{"+elem.styling+"}" + styling.substring(index)
                }
            }
        }else if (elem.type == 'media'){
            if(document.getElementById(elem.constraints) == undefined){
                let styleElem = document.createElement('style');
                styleElem.id = elem.constraints;
                document.body.appendChild(styleElem)
                addStyling(elem.childern,styleElem)
                styleElem.innerHTML = `@media only screen and ${elem.constraints}{${styleElem.innerHTML}}`
            }else{
                let styleElem = document.getElementById(elem.constraints);
                styleElem.innerHTML = styleElem.innerHTML.slice(0, -1);
                addStyling(elem.childern,styleElem)
                styleElem.innerHTML = styleElem.innerHTML + '}';
            }
        }
    }
}
function combineStyling(orgObj, newObj){
    let orgProps = Object.keys(orgObj);
    let newProps = Object.keys(newObj);
    for(let i = 0; i < newProps.length; i++){
        console.log(newProps[i])
        if(orgProps.indexOf(newProps[i]) != -1){
            orgObj[newProps[i]] = newObj[newProps[i]]

        }else{
            console.log('notfon')
            orgObj[newProps[i]] = newObj[newProps[i]]
            console.log(orgObj)
        }
    }
    return orgObj;
}
export {addStyling, combineStyling};